import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\app-router.js");
;
import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\client-page.js");
;
import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\error-boundary.js");
;
import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\layout-router.js");
;
import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "D:\\work\\じ-自在な整骨院\\01_site-data\\zizaina-com-2024\\node_modules\\next\\dist\\client\\components\\render-from-template-context.js");
